<template>
<OModal ref="modal"  @shown="handleShown">
    <div class="modal-dialog modal-lg">
        <div class="modal-content ">
            <div class="modal-header">
                <h5 class="modal-title">
                    {{ title??$t("Map")}}
                </h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <Leaflet v-if="item" :item="item" style="height: 300px;position: relative;outline-style: none;" :config="config" ref="mapRef"/>
            </div>
            
        </div>
    </div>
</OModal>
</template>
<script setup>
    import {ref} from 'vue';
    import Leaflet from 'system.vue.components.Leaflet.vue';
    const props = defineProps(['item','config','title']);
    const modal = ref(null);
    const mapRef = ref(null);


    function handleShown(){
        window.setTimeout(()=>{
            mapRef.value.getControl().map?.invalidateSize()
        },100)
        
    }


    defineExpose({modal});
</script>